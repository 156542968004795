import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { de } from 'date-fns/locale/de';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import {
  CustomMissingTranslationHandler,
  MIDUCA_DATE_FORMATS,
  TranslationLoader,
} from '@miduca/core';
import { ERV_API_INJECT_TOKEN } from '../../../erv-api/src/public-api';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

const translationLoaderFactory = (http: HttpClient) =>
  new TranslationLoader(http, environment.translationsUrl, 'ERV');

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideAnimations(),
    provideDateFnsAdapter(MIDUCA_DATE_FORMATS),
    { provide: MAT_DATE_FORMATS, useValue: MIDUCA_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: de },
    importProvidersFrom(
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: CustomMissingTranslationHandler,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: translationLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'de',
      }),
      MsalModule.forRoot(
        new PublicClientApplication({
          auth: {
            clientId: environment.msalConfig.clientId,
            authority: environment.msalConfig.authority,
            redirectUri: environment.msalConfig.redirectUri,
          },
          cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
          },
        }),
        {
          interactionType: InteractionType.Redirect, // Msal Guard Configuration
          authRequest: {
            scopes: environment.msalConfig.scopes,
          },
        },
        {
          interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
          protectedResourceMap: environment.msalConfig.protectedResourceMap,
        }
      )
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    {
      provide: ERV_API_INJECT_TOKEN,
      useValue: environment.ervUrl,
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
};
