import { MsalGuard } from '@azure/msal-angular';
import { Routes } from '@angular/router';
import { InsuredComponent } from './insured/insured.component';
import { PremiumsComponent } from './premiums/premiums.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'premiums',
    pathMatch: 'full',
  },
  {
    path: 'premiums',
    canActivate: [MsalGuard],
    loadComponent: () =>
      import('./premiums/premiums.component').then(
        comp => comp.PremiumsComponent
      ),
  },
  {
    path: 'insured',
    canActivate: [MsalGuard],
    loadComponent: () =>
      import('./insured/insured.component').then(comp => comp.InsuredComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then(comp => comp.LoginComponent),
  },
];
