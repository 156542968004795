import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    const translations =
      params.translateService.translations[params.translateService.currentLang];

    if (translations) {
      const keys = Object.keys(translations);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key.toUpperCase() === params.key.toUpperCase()) {
          return translations[key];
        }
      }
    }
    return params.key;
  }
}
