import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { trimEnd } from '../helpers/url';

export class TranslationLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private translationsUrl: string,
    private keyprefix: string = ''
  ) {}

  getTranslation(lang: string): Observable<Map<string, string>> {
    return this.http.get<Map<string, string>>(
      this.getUri(lang, this.keyprefix)
    );
  }

  private getUri(lang: string, area?: string): string {
    let uri = `${trimEnd(this.translationsUrl, '/')}/${lang}`;
    if (area?.length) {
      uri += `/${area}`;
    }
    return uri;
  }
}
